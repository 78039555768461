import React, { useState, useEffect } from "react";
import { Form, Modal, Tabs, Tab, Row, Col } from "react-bootstrap";
import Select, { components } from "react-select";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";
import "./esg.css";

const Esgdownload = ({
  setFinancialYearId,
  setShow,
  financialYear,
  frameworkValue,
  setFinancialYear,
  setTimePeriods,
  currentTab,
  keyTab,
  setKeyTab,
  setLocationOption,
  setFramework,
  framework,
  downloadPdf,
  setFromDate,
  setToDate,
  show,
  handleClose,
  // showFilter,
  // handleCloseFilter,
}) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  const [selectedPeriod, setSelectedPeriod] = useState([]);
  const [meterList, setMeterList] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2023-2024");
  const [showFilter, setShowFilter] = useState(false);

  const handleBRSRDownload = () => {
    const url =
      "https://riu-bucket.s3.ap-south-1.amazonaws.com/uploads/Business%20responsibility%20and%20sustainability%20report.pdfBusiness%20responsibility%20and%20sustainability%20report.pdf";
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Opens in a new tab
    link.download = url.split("/").pop();
    document.body.appendChild(link); // Append to body
    link.click();
    document.body.removeChild(link); // Clean up
  };

  const handleBRSRGudeLineDownload = () => {
    const url =
      "https://riu-bucket.s3.ap-south-1.amazonaws.com/uploads/Business%20responsibility%20and%20sustainability%20reporting%20by%20listed%20entitiesAnnexure2_p.PDFBusiness%20responsibility%20and%20sustainability%20reporting%20by%20listed%20entitiesAnnexure2_p.PDF";
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Opens in a new tab
    link.download = url.split("/").pop();
    document.body.appendChild(link); // Append to body
    link.click();
    document.body.removeChild(link); // Clean up
  };

  useEffect(() => {
    if (currentUser?.frequency === "HALF_YEARLY") {
      setSelectedPeriod([{ value: "H1", label: "H1" }]);
    } else if (currentUser?.frequency === "YEARLY") {
      setSelectedPeriod([{ value: "Y1", label: "Y1" }]);
    } else if (currentUser?.frequency === "QUARTERLY") {
      setSelectedPeriod([{ value: "Q1", label: "Q1" }]);
    } else if (currentUser?.frequency === "MONTHLY") {
      setSelectedPeriod([{ value: "M1", label: "M1" }]);
    }

    getFinancialYear();
    getSource();
  }, []);

  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setFinancialYear(data.data);
      const lastEntry = data.data[data.data.length - 1];
      setSelectedYear(lastEntry.financial_year_value);
      setFinancialYearId(lastEntry.id);
    }
  };

  const getSource = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const locationArray = data?.data?.reverse().map((item) => ({
        id: item.id,
        unitCode: item?.unitCode,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
      }));
      setMeterList(locationArray);
    }
  };

  useEffect(() => {
    if (meterList.length > 1 && selectedLocations.length === 0) {
      const firstLocation = {
        value: meterList[1].location,
        label: meterList[1].location,
        unitCode: meterList[1].unitCode,
        id: meterList[1].id,
      };
      setSelectedLocations([firstLocation]);
      setLocationOption([firstLocation]);
    } else if (meterList.length > 0 && selectedLocations.length === 0) {
      const firstLocation = {
        value: meterList[0].location,
        label: meterList[0].location,
        unitCode: meterList[0].unitCode,
        id: meterList[0].id,
      };
      setSelectedLocations([firstLocation]);
      setLocationOption([firstLocation]);
    }

    const periodOptionsForFrequency =
      periodOptions[currentUser?.frequency] || [];
    if (periodOptionsForFrequency.length > 0 && selectedPeriod.length === 0) {
      const firstPeriod = periodOptionsForFrequency[0];
      setSelectedPeriod([firstPeriod]);
      handlePeriodChange([firstPeriod]);
    }
  }, [meterList]);

  const handleSelectionChange = (selectedOptions) => {
if (selectedOptions.length === 0) {
      // If all options are deselected (including "All"), clear the selection
      setLocationOption([]);
      setSelectedLocations([]);
    } else {
      // Otherwise, just set the selected options normally
      setLocationOption(selectedOptions);
      setSelectedLocations(selectedOptions);
    }
  };

  const handleLocationChangeSingle = (selectedOptions) => {
    if (selectedOptions.length === 0) {
      // If no options are selected, clear everything
      setLocationOption([]);
      setSelectedLocations([]);
    } else {
      // Otherwise, just set the selected options normally
      setLocationOption(selectedOptions);
      setSelectedLocations(selectedOptions);
    }
  };

  useEffect(() => {
    if (keyTab === "individual") {
      setSelectedLocations((prevState) => {
        console.log("prev",prevState)
        return prevState.length > 0 ? [prevState[0]] : [];
      });
      setLocationOption((prevState) => {
        console.log("prev",prevState)
        return prevState.length > 0 ? [prevState[0]] : [];
      });
      const periodOptionsForFrequency =
      periodOptions[currentUser?.frequency] || [];
    if (periodOptionsForFrequency.length > 0 && selectedPeriod.length > 0) {
      const firstPeriod = selectedPeriod[0];
      setSelectedPeriod([firstPeriod]);
      handlePeriodChange([firstPeriod]);
    }
    }
  }, [keyTab]);

  const calculateDateRange = (type, period, startingMonth, year) => {
    const startMonth = ((startingMonth - 1 + (period - 1) * type) % 12) + 1;
    const startYear =
      year + Math.floor((startingMonth - 1 + (period - 1) * type) / 12);
    const endMonth = ((startMonth - 1 + type) % 12) + 1;
    const endYear = startYear + Math.floor((startMonth - 1 + type) / 12);

    const formatDate = (month, year) =>
      `${year}-${month < 10 ? `0${month}` : month}`;

    return {
      fromDate: formatDate(startMonth, startYear),
      toDate: formatDate(endMonth, endYear),
    };
  };

  // const periodOptions = {
  //   HALF_YEARLY: [
  //     { value: "H1", label: "H1" },
  //     { value: "H2", label: "H2" },
  //   ],
  //   QUARTERLY: [
  //     { value: "Q1", label: "Q1" },
  //     { value: "Q2", label: "Q2" },
  //     { value: "Q3", label: "Q3" },
  //     { value: "Q4", label: "Q4" },
  //   ],
  //   MONTHLY: Array.from({ length: 12 }, (_, i) => ({
  //     value: `M${i + 1}`,
  //     label: `M${i + 1}`,
  //   })),
  //   YEARLY: [{ value: "Y1", label: "Y1" }],
  // };

  // const allOption = { value: 'all', label: 'All' };

  const periodOptions = {
    HALF_YEARLY: [
      { value: "H1", label: "H1" },
      { value: "H2", label: "H2" },
    ],
    QUARTERLY: [
      { value: "Q1", label: "Q1" },
      { value: "Q2", label: "Q2" },
      { value: "Q3", label: "Q3" },
      { value: "Q4", label: "Q4" },
    ],
    MONTHLY: [
      ...Array.from({ length: 12 }, (_, i) => ({
        value: `M${i + 1}`,
        label: `M${i + 1}`,
      })),
    ],
    YEARLY: [
      { value: "Y1", label: "Y1" },
    ],
  };

  const handleYearChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = financialYear.find(
      (year) => year.financial_year_value === selectedValue
    );
    setSelectedYear(selectedValue);
    setFinancialYearId(selectedOption?.id || "");
  };

  const handlePeriodChange = (selectedOptions) => {
if (selectedOptions.length === 0) {
      selectedOptions = [];
    }

    setSelectedPeriod(selectedOptions);
    const newTimePeriods = {};
    const year = parseInt(selectedYear.split("-")[0]);

    let earliestFromDate = null;
    let latestToDate = null;

    selectedOptions.forEach((period) => {
      let dateRange;

      if (period?.value?.startsWith("H")) {
        const halfYear = period.value === "H1" ? 1 : 2;
        dateRange = calculateDateRange(
          6,
          halfYear,
          currentUser.starting_month,
          year
        );
      } else if (period?.value?.startsWith("Q")) {
        const quarter = parseInt(period.value.replace("Q", ""));
        dateRange = calculateDateRange(
          3,
          quarter,
          currentUser.starting_month,
          year
        );
      } else if (period?.value?.startsWith("M")) {
        const month = parseInt(period.value.replace("M", ""));
        dateRange = calculateDateRange(
          1,
          month,
          currentUser.starting_month,
          year
        );
      } else if (period?.value === "Y1") {
        dateRange = calculateDateRange(12, 1, currentUser.starting_month, year);
      }

      if (dateRange) {
        newTimePeriods[period?.value?.toLowerCase()] = dateRange?.fromDate;

        if (
          !earliestFromDate ||
          new Date(dateRange.fromDate) < new Date(earliestFromDate)
        ) {
          earliestFromDate = dateRange.fromDate;
        }
        if (
          !latestToDate ||
          new Date(dateRange.toDate) > new Date(latestToDate)
        ) {
          latestToDate = dateRange.toDate;
        }
      }
    });

    setFromDate(earliestFromDate);
    setToDate(latestToDate);
    setTimePeriods(newTimePeriods);
  };

  const handlePeriodChangeSingle = (selectedOption) => {
    // Check if the selected option is valid
    if (!selectedOption) {
      setSelectedPeriod([]); // Clear selection if no option is selected
      setFromDate(null);
      setToDate(null);
      setTimePeriods({});
      return;
    }

    setSelectedPeriod([selectedOption]); // Set the selected period as an array

    const newTimePeriods = {};
    const year = parseInt(selectedYear.split("-")[0]);
    let dateRange;

    if (selectedOption.value.startsWith("H")) {
      const halfYear = selectedOption.value === "H1" ? 1 : 2;
      dateRange = calculateDateRange(
        6,
        halfYear,
        currentUser.starting_month,
        year
      );
    } else if (selectedOption.value.startsWith("Q")) {
      const quarter = parseInt(selectedOption.value.replace("Q", ""));
      dateRange = calculateDateRange(
        3,
        quarter,
        currentUser.starting_month,
        year
      );
    } else if (selectedOption.value.startsWith("M")) {
      const month = parseInt(selectedOption.value.replace("M", ""));
      dateRange = calculateDateRange(
        1,
        month,
        currentUser.starting_month,
        year
      );
    } else if (selectedOption.value === "Y1") {
      dateRange = calculateDateRange(12, 1, currentUser.starting_month, year);
    }

    if (dateRange) {
      newTimePeriods[selectedOption.value.toLowerCase()] = dateRange.fromDate;
      setFromDate(dateRange.fromDate);
      setToDate(dateRange.toDate);
    } else {
      setFromDate(null);
      setToDate(null);
    }

    setTimePeriods(newTimePeriods);
  };


  // Add the "All" option to the dynamically generated options
  const locationOptions = [
    ...meterList.map((item) => ({
      value: item.location,
      label: item.location,
      unitCode: item?.unitCode,
      id: item?.id,
    })),
  ];

  const frameworkOptions = [

    ...frameworkValue.map((framework) => ({
      label: framework.title,
      value: framework.id,
    })),
  ];

  useEffect(() => {
    // Filter out "All" from frameworkOptions before setting the state
    let filteredFrameworkOptions;

    if (keyTab === "individual") {
      filteredFrameworkOptions = frameworkOptions;
    } else {
      filteredFrameworkOptions = frameworkOptions;
    }

    // Only update framework if the options are different
    setFramework(filteredFrameworkOptions);
  }, [keyTab, frameworkValue]); // Depend on key and frameworkOptions

  const handleFrameworkChange = (selectedOptions) => {
 if (selectedOptions.length === 0) {
      // If all options are deselected (including "All"), clear the selection
      setFramework([]);
    } else {
      // Otherwise, just set the selected options normally
      setFramework(selectedOptions);
    }
  };

  const handleFrameworkChangeSingle = (selectedOptions) => {
    if (selectedOptions.length === 0) {
      // If no options are selected, clear everything
      setFramework([]);
    } else {
      // Otherwise, just set the selected options normally
      setFramework(selectedOptions);
    }
  };

  const CustomOption = (props) => {
    const { isSelected, data } = props;
  
    const renderLabel = () => {
      if (data.label.includes(",")) {
        const words = data.label.split(",").map(word => word.trim());
        const secondWord = words[1] || ""; // Get the second word or an empty string if it doesn't exist
        const fourthLastWord = words[words.length - 4] || ""; // Get the fourth last word or an empty string if it doesn't exist
  
        return (
          <div>
            {secondWord}, {fourthLastWord} {/* Render the second and fourth last words */}
          </div>
        );
      } else {
        return <div>{data.label}</div>; // Render data.label as is
      }
    };
  
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div
            style={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                border: "2px solid #3f88a5",
                borderRadius: "2px",
                backgroundColor: isSelected ? "transparent" : "transparent",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Tick mark when selected */}
              {isSelected && (
                <span style={{ color: "white", fontSize: "14px" }}>✔</span>
              )}
            </div>
          </div>
  
          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            {renderLabel()} {/* Call the renderLabel function */}
          </div>
        </div>
      </components.Option>
    );
  };
  const CustomMultiValue = () => null;

  const CustomControl = (props) => {
    const { selectProps } = props;
    const { value, placeholder } = selectProps;
  
    // Function to render the label based on conditions
    const renderLabel = () => {
      if (value && value.length > 0) {
        const label = value[0].label;
        if (label.includes(",")) {
          const words = label.split(",").map(word => word.trim());
          const secondWord = words[1] || ""; // Get the second word or an empty string if it doesn't exist
          const fourthLastWord = words[words.length - 4] || ""; // Get the fourth last word or an empty string if it doesn't exist
          return `${secondWord}, ${fourthLastWord}`.trim(); // Return the formatted label
        } else {
          return label; // Return the label as is
        }
      }
      return ""; // Return an empty string if no value
    };
  
    return (
      <components.Control {...props}>
        {/* Placeholder or selected value */}
        {(!value || value.length === 0) && (
          <div
            style={{
              color: "#3f88a5",
              fontWeight: 600,
              fontSize: "13px",
              position: "absolute",
              left: "5px",
              pointerEvents: "none",
            }}
          >
            {placeholder}
          </div>
        )}
        {/* Display the processed selected value */}
        {value && value.length > 0 && (
          <div style={{ color: "#3f88a5", marginLeft: "5px" }}>
            {renderLabel()} {/* Call the renderLabel function */}
          </div>
        )}
  
        {/* Ensure you still render the child components like the dropdown indicator and input */}
        {props.children}
      </components.Control>
    );
  };

  const CustomClearIndicator = () => null; 

  const handleCloseFilter = () => setShowFilter(false);
  return (
    <div style={{ width: "100%" }}>
      <div className="color_div_Current mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="color_rent mb-0">
            <button onClick={() => setShow(true)} className="esg_button_style">
              Download Report
            </button>
          </div>

          <div className="color_rent mb-0 leftmarg">
            <Form.Select
              className="esg_text2"
              onChange={handleYearChange}
              value={selectedYear}
              style={{ padding: ".375rem 2.25rem .375rem .75rem" }}
            >
              {financialYear.map((year) => (
                <option key={year.id} value={year.financial_year_value}>
                  FY - {"           "}
                  {year.financial_year_value}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="color_rent mb-0 leftmarg">
            <button
              onClick={() => setShowFilter(true)}
              className="esg_button_style"
            >
              Filter
            </button>
          </div>
        </div>
      </div>
      <Modal size="md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <label className="align-items-center">ESG REPORT</label>
        </Modal.Header>
        <Modal.Body>
          <p>DOWNLOAD YOUR REQUIRED REPORT BELOW</p>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",

              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ width: "40%" }}>
              <button
                onClick={handleBRSRDownload}
                className="new_button_style"
                style={{ width: "100%", fontSize: "10px" }}
              >
                BRSR Report Format
              </button>
            </div>
            <div style={{ width: "40%" }}>
              <button
                onClick={handleBRSRGudeLineDownload}
                className="new_button_style"
                style={{ width: "100%", fontSize: "10px" }}
              >
                BRSR Guideline
              </button>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "40%" }}>
              <button
                className="new_button_style"
                style={{ width: "100%", fontSize: "10px" }}
                onClick={() => downloadPdf("WORD")}
              >
                DOWNLOAD ESG REPORT (Word Documents)
              </button>
            </div>
            <div style={{ width: "40%" }}>
              <button
                className="new_button_style"
                style={{ width: "100%", fontSize: "10px" }}
                onClick={() => downloadPdf("PDF")}
              >
                DOWNLOAD ESG REPORT (PDF)
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={showFilter} onHide={handleCloseFilter}>
        <Modal.Header closeButton>
          <div
            style={{ color: "#3f88a5", fontSize: "24px", fontWeight: "bold" }}
          >
            Filters
          </div>
        </Modal.Header>
        <Modal.Body>
          {currentTab !== 0 ? <Tabs
            defaultActiveKey={keyTab}
            id="filter-tabs"
            className="mb-3 custom-tabs"
            onSelect={(k) => setKeyTab(k)}
          >
            {/* Combined Tab */}
           {meterList && meterList.length > 1 && <Tab
              eventKey="combined"
              style={{}}
              tabClassName="custom-tab-esg"
              title="Combined"
            >
              <div
                style={{
                  marginBottom: "1em",
                  color: "#b80000",
                  fontWeight: 600,
                }}
              >
                Note* Can select all the options in each filter (to see combined
                value).
              </div>

              <Row>
                <Col md={4} style={{ marginRight: "5px" }}>
                  <div className="">
                    {currentUser?.frequency === "HALF_YEARLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {                           handlePeriodChange(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={periodOptions.HALF_YEARLY || []}
                        isClearable={false}
                        placeholder="Select Frequency"
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,
                        }}
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          placeholder: (base) => ({
                            ...base,
                            position: "absolute", // Ensure the placeholder doesn't shift with input
                            top: "50%",
                            transform: "translateY(-50%)", // Vertically center the placeholder
                            pointerEvents: "none", // Disable interaction on the placeholder
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "QUARTERLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                           handlePeriodChange(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={periodOptions.QUARTERLY || []}
                        placeholder="Select Periods"
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "MONTHLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                           handlePeriodChange(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={periodOptions.MONTHLY || []}
                        placeholder="Select Periods"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "YEARLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                           handlePeriodChange(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={periodOptions.YEARLY || []}
                        placeholder="Select Periods"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                  </div>
                </Col>
                {meterList && meterList.length > 1 && (
                  <Col md={7} style={{ marginRight: "5px" }}>
                    <div className=" ">
                      <Select
                        isMulti
                        isClearable={false}
                        value={selectedLocations}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 2) {
                            alert("Please select at least two option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                            handleSelectionChange(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={locationOptions || []}
                        placeholder="Select Locations"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    </div>
                  </Col>
                )}
                {frameworkOptions && frameworkOptions.length > 2 && (
                  <Col md={4}>
                    <div className="">
                      <Select
                        isMulti
                        options={frameworkOptions}
                        value={framework}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length <= 5) {
                            handleFrameworkChange(selectedOptions);
                          }
                        }}
                        isClearable={false}
                        placeholder="Select Framework(s)"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Tab>}

            {/* Compare Tab */}
            <Tab
              eventKey="compare"
              style={{}}
              tabClassName="custom-tab-esg"
              title="Compare"
            >
              <div
                style={{
                  marginBottom: "1em",
                  color: "#b80000",
                  fontWeight: 600,
                }}
              >
                Note* Can select all the options in each filter (to compare
                value).
              </div>

              <Row>
                <Col md={4} style={{ marginRight: "5px" }}>
                  <div className="">
                    {currentUser?.frequency === "HALF_YEARLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                           handlePeriodChange(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={periodOptions.HALF_YEARLY || []}
                        isClearable={false}
                        placeholder="Select Periods"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "QUARTERLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                           handlePeriodChange(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={periodOptions.QUARTERLY || []}
                        placeholder="Select Periods"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          ClearIndicator:CustomClearIndicator,

                          MultiValue: CustomMultiValue,
                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "MONTHLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                           handlePeriodChange(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={periodOptions.MONTHLY || []}
                        placeholder="Select Periods"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "YEARLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                           handlePeriodChange(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={periodOptions.YEARLY || []}
                        placeholder="Select Periods"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                  </div>
                </Col>
                {meterList && meterList.length > 1 && (
                  <Col md={7} style={{ marginRight: "5px" }}>
                    <div className=" ">
                      <Select
                        isMulti
                        isClearable={false}
                        value={selectedLocations}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                            handleSelectionChange(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={locationOptions || []}
                        placeholder="Select Locations"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          ClearIndicator:CustomClearIndicator,

                          MultiValue: CustomMultiValue,
                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    </div>
                  </Col>
                )}
                {frameworkOptions && frameworkOptions.length > 2 && (
                  <Col md={4}>
                    <div className="">
                      <Select
                        isMulti
                        options={frameworkOptions}
                        value={framework}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length <= 5) {
                            handleFrameworkChange(selectedOptions);
                          }
                        }}
                        isClearable={false}
                        placeholder="Select Framework(s)"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Tab>

            {/* Individual Tab */}
            <Tab
              eventKey="individual"
              style={{}}
              tabClassName="custom-tab-esg"
              title="Individual"
            >
              <div
                style={{
                  marginBottom: "1em",
                  color: "#b80000",
                  fontWeight: 600,
                }}
              >
                Note* Can select only one option in each filter.
              </div>

              <Row>
                <Col md={4} style={{ marginRight: "5px" }}>
                  <div className="">
                    {currentUser?.frequency === "HALF_YEARLY" && (
                      <Select
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                            handlePeriodChangeSingle(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={
                          periodOptions.HALF_YEARLY || []
                        }
                        isClearable={false}
                        placeholder="Select Periods"
                        isMulti={false}
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          SingleValue: CustomMultiValue,
                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "QUARTERLY" && (
                      <Select
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                            handlePeriodChangeSingle(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        options={
                          periodOptions.QUARTERLY || []
                        }
                        isClearable={false}
                        placeholder="Select Periods"
                        isMulti={false}
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          SingleValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "MONTHLY" && (
                      <Select
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                            handlePeriodChangeSingle(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        options={
                          periodOptions.MONTHLY|| []
                        }
                        isClearable={false}
                        placeholder="Select Periods"
                        isMulti={false}
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          SingleValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "YEARLY" && (
                      <Select
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                            handlePeriodChangeSingle(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        options={
                          periodOptions.YEARLY|| []
                        }
                        isClearable={false}
                        placeholder="Select Periods"
                        isMulti={false}
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          SingleValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                  </div>
                </Col>
                {meterList && meterList.length > 1 && (
                  <Col md={7} style={{ marginRight: "5px" }}>
                    <div className=" ">
                      <Select
                        isClearable={false}
                        value={selectedLocations}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < 1) {
                            alert("Please select at least one option."); // Alert for minimum selection
                          } else if (selectedOptions.length > 5) {
                            alert("You can select a maximum of 5 options."); // Alert for maximum selection
                          } else {
                            handleLocationChangeSingle(selectedOptions); // Handle selection change if valid
                          }
                        }}
                        
                        options={
                          locationOptions || []
                        } // Filter out "All" option
                        isMulti
                        placeholder="Select Locations"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                          SingleValue: CustomMultiValue,
                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    </div>
                  </Col>
                )}
                {frameworkOptions && frameworkOptions.length > 2 && (
                  <Col md={4}>
                    <div className="">
                      <Select
                        options={
                          frameworkOptions|| []
                        } // Filter out "All" option
                        value={framework}
                        onChange={handleFrameworkChangeSingle} // Updated to the new function
                        isClearable={false}
                        placeholder="Select Framework"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          SingleValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Tab>
          </Tabs>:
          <>
          <Row>
                <Col md={4} style={{ marginRight: "5px" }}>
                  <div className="">
                    <div style={{color:" #3f88a5",fontSize:"18px",fontWeight:600}}>Select Frequency</div>
                    {currentUser?.frequency === "HALF_YEARLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length <= 5) {
                            handlePeriodChange(selectedOptions);
                          }
                        }}
                        options={periodOptions.HALF_YEARLY || []}
                        isClearable={false}
                        placeholder="Select Frequency"
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          placeholder: (base) => ({
                            ...base,
                            position: "absolute", // Ensure the placeholder doesn't shift with input
                            top: "50%",
                            transform: "translateY(-50%)", // Vertically center the placeholder
                            pointerEvents: "none", // Disable interaction on the placeholder
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "QUARTERLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length <= 5) {
                            handlePeriodChange(selectedOptions);
                          }
                        }}
                        options={periodOptions.QUARTERLY || []}
                        placeholder="Select Periods"
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                          ClearIndicator:CustomClearIndicator,

                        }}
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "MONTHLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length <= 5) {
                           handlePeriodChange(selectedOptions);
                          }
                        }}
                        options={periodOptions.MONTHLY || []}
                        placeholder="Select Periods"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          ClearIndicator:CustomClearIndicator,

                          MultiValue: CustomMultiValue,
                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                    {currentUser?.frequency === "YEARLY" && (
                      <Select
                        isMulti
                        value={selectedPeriod}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length <= 5) {
                           handlePeriodChange(selectedOptions);
                          }
                        }}
                        options={periodOptions.YEARLY || []}
                        placeholder="Select Periods"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          ClearIndicator:CustomClearIndicator,

                          MultiValue: CustomMultiValue,
                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    )}
                  </div>
                </Col>
                {meterList && meterList.length > 1 && (
                  <Col md={7} style={{ marginRight: "5px" }}>
                    <div className=" ">
                    <div style={{color:" #3f88a5",fontSize:"18px",fontWeight:600}}>Select Locations</div>
                      <Select
                        isMulti
                        isClearable={false}
                        value={selectedLocations}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length <= 5) {
                            handleSelectionChange(selectedOptions);
                          }
                        }}
                        options={locationOptions || []}
                        placeholder="Select Locations"
                        className=""
                        components={{
                          Option: CustomOption,
                          Control: CustomControl,
                          ClearIndicator:CustomClearIndicator,

                          MultiValue: CustomMultiValue,
                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    </div>
                  </Col>
                )}
                {frameworkOptions && frameworkOptions.length > 2 && (
                  <Col md={4}>
                    <div className="">
                      <Select
                        isMulti
                        options={frameworkOptions}
                        value={framework}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length <= 5) {
                            handleFrameworkChange(selectedOptions);
                          }
                        }}
                        isClearable={false}
                        placeholder="Select Framework(s)"
                        className=""
                        components={{
                          Option: CustomOption,
                          ClearIndicator:CustomClearIndicator,

                          Control: CustomControl,
                          MultiValue: CustomMultiValue,
                        }}
                        hideSelectedOptions={false} // Keep selected options in the dropdown
                        closeMenuOnSelect={false} // Prevent dropdown from closing
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 100, // Ensure the menu appears above other elements
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: "#3f88a5", // Change color of the dropdown arrow
                            padding: "0 10px", // Adjust padding for the indicator
                            fontSize: "20px", // Increase the font size of the indicator
                            minHeight: "20px", // Set a minimum height for the indicator
                            minWidth: "20px", // Set a minimum width for the indicator
                          }),
                          multiValue: (base) => ({
                            ...base,
                            background: "transparent",
                            border: "2px solid #3f88a5",
                            borderRadius: "10px",
                            marginTop: "0.5em",
                          }),
                          option: (provided, state) => ({
                            ...provided,

                            backgroundColor: state.isSelected
                              ? "transparent" // Selected option background color
                              : state.isFocused
                              ? "white" // Focused option background color
                              : "white", // Default option background color
                            color: state.isSelected ? "black" : "black", // Text color based on state
                            cursor: "pointer",
                          }),
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>
          
          </>}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button>
            SAVE
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Esgdownload;
