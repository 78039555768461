import React from "react";
import TrainingRegistrationStatus from "./TrainingRegistrationStatus";
import TrainingDetails from "../../TrainingModule/components/TrainingDetails";
import TrainingCatalogue from "../../TrainingModule/components/TrainingCatalogue";
import TrainingCatalogueOverview from "./TrainingCatalogueOverview";
import TrainingDetailsOverview from "./TrainingDetailsOverview";

const Training = () => {
  
  return (
    <div>
      <div
        className="d-flex  flex-space-between mt-5"
        style={{ height: "40vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy  "
          style={{ padding:'20px', backgroundColor:'white',width:"50%",borderRadius:'10px',height:"40vh",overflow:"auto" }}
        >
          <TrainingRegistrationStatus type="registration" />
        </div>
        <div className="secondhalfprogress " style={{width:"50%",height:"40vh",overflow:"auto",backgroundColor:'white',borderRadius:'10px'  }}>
          {/* <TrainingCatalogue /> */}
          <TrainingCatalogueOverview/>
        </div>
      </div>

      <div
        className="d-flex  flex-space-between "
        style={{ height: "40vh", marginBottom: "3%", overflow:"auto" }}
      >
        <div
          className="firsthalfprogressenergy "
          style={{ width:"50%",padding:'20px',backgroundColor:'white',borderRadius:'10px',height:"40vh",overflow:"auto" }}
        >
          <TrainingRegistrationStatus type="history" />
        </div>
        <div className="secondhalfprogress " style={{width:"50%",height:"40vh",overflow:"auto",backgroundColor:'white',borderRadius:'10px'  }}>
          {/* <TrainingDetails type='HISTORY' /> */}
          <TrainingDetailsOverview type='HISTORY' />
        </div>
      </div>
    </div>
  );
};

export default Training;
