import React, { useEffect, useState } from "react";
import "./TrainingModal.css";
import CloseIcon from "../img/Close.png";
import "bootstrap/dist/css/bootstrap.min.css";
import img from "../img/filter.svg";
import TrainingFilterModal from "./TrainingFilterModal";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { Button, Modal } from "react-bootstrap";

const TrainingModal = ({ show, handleClose, actionId, editData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [topicMapping, setTopicMapping] = useState([]);
  const [trainingTopicData, setTrainingTopicData] = useState([]);
  const [formData, setFormData] = useState({
    trainingTitle: "",
    description: "",
    trainingFacilitator: "",
    trainers: "",
    departmentId: 0,
    date: "",
    fromTime: "",
    toTime: "",
    targetAudience: "EMPLOYEES",
    modeOfTraining: "ONLINE",
    registrationDeadline: "",
    linkOrVenues: "",
    companyId: 0,
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const audienceOptions = [
    { label: "Permanent Employees", value: "EMPLOYEES_PERMANENT" },
    { label: "Other than Permanent Employees", value: "EMPLOYEES_TEMPORARY" },
    { label: "Permanent Workers", value: "WORKERS_PERMANENT" },
    { label: "Other than Permanent Workers", value: "WORKERS_TEMPORARY" },
    { label: "KMP", value: "KMP" },
    { label: "BOD", value: "BOD" },
  ];
  const [showContent, setShowContent] = useState(false);
  const [content, setContent] = useState("");

  const handleContentClose = () => setShowContent(false);
  const handleShow = (content) => {
    setContent(content);
    setShowContent(true);
  };

  const toggleOption = (value) => {
    if (value === "ALL") {
      if (selectedOptions.length === audienceOptions.length) {
        setSelectedOptions([]);
        setFormData((prevData) => ({
          ...prevData,
          ["targetAudience"]: [],
        }));
      } else {
        setSelectedOptions(audienceOptions.map((option) => option.value));
        setFormData((prevData) => ({
          ...prevData,
          ["targetAudience"]: audienceOptions.map((option) => option.value),
        }));
      }
    } else {
      if (selectedOptions.includes(value)) {
        setSelectedOptions(
          selectedOptions.filter((selected) => selected !== value)
        );
        setFormData((prevData) => ({
          ...prevData,
          ["targetAudience"]: selectedOptions.filter(
            (selected) => selected !== value
          ),
        }));
      } else {
        setSelectedOptions([...selectedOptions, value]);
        setFormData((prevData) => ({
          ...prevData,
          ["targetAudience"]: [...selectedOptions, value],
        }));
      }
    }
  };

  const isAllSelected = selectedOptions.length === audienceOptions.length;
  const getTrainingTopicMapping = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTrainingTopicMapping`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTrainingTopicData(data?.data);
      const filteredArray = editData
        ? data?.data.filter((obj) => editData.trainingTopicID.includes(obj.id))
        : [];
      setTopicMapping(filteredArray);
    }
  };

  useEffect(() => {
    if (editData) {
      getTrainingTopicMapping();
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...editData,
      }));
    }
  }, [editData]);

  useEffect(() => {
    getTrainingTopicMapping();
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!show) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const trainingTopicID =
      topicMapping && topicMapping.length
        ? topicMapping.map((item) => item.id)
        : [];

    const principlesId =
      topicMapping && topicMapping.length
        ? topicMapping.map((item) => item.trainingPrincipleId)
        : [];

    const payload = {
      ...formData,
      trainingTopicID,
      date: new Date(formData.date),
      principlesId,
      companyId: Number(localStorage.getItem("user_temp_id")),
      ...(actionId ? { trainingId: actionId } : {}),
    };

    const apiUrl = `${config.POSTLOGIN_API_URL_COMPANY}${
      actionId ? "updateTraining" : "createNewTraining"
    }`;

    try {
      const { isSuccess, data } = await apiCall(apiUrl, {}, payload, "POST");

      if (isSuccess) {
        handleClose();
      }
    } catch (error) {
      console.error("API call failed", error);
    }
  };

  const formatDateToInputValue = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h5>{editData ? "Edit" : "Create"} Training</h5>
          <button onClick={handleClose} className="close-button">
            <img src={CloseIcon} alt="close" />
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="row mb-4">
                <div className="col-md-6">
                  <label>Training Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="trainingTitle"
                    placeholder="Online mandatory trainings (global)"
                    value={formData.trainingTitle}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 ms-3">
                  <label>Training Topic</label>
                  <div
                    className="select-container"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      // onDoubleClick={()=>handleShow(topicMapping && topicMapping.length
                      //   ? topicMapping.map((item) => item.topic).join(", ")
                      //   : "select training topic")}
                      value={
                        topicMapping && topicMapping.length
                          ? topicMapping.map((item) => item.topic).join(", ")
                          : "select training topic"
                      }
                      readOnly
                    />
                    <div
                      className="filter-icon"
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        openModal();
                      }}
                    >
                      <img src={img} alt="Filter" />
                    </div>
                  </div>

                  {isModalOpen && (
                    <TrainingFilterModal
                      onClose={closeModal}
                      topicMapping={topicMapping}
                      setTopicMapping={setTopicMapping}
                      trainingTopicData={trainingTopicData}
                    />
                  )}
                </div>
              </div>

              <div className="row mb-4 ">
                <div className="col-md-6">
                  <label>KPI's</label>
                  <input
                    type="text"
                    className="form-control"
                    name="kpis"
                    placeholder="Automated Response After Selecting The Topic"
                    value={
                      topicMapping && topicMapping.length
                        ? topicMapping
                            .map((item) => item.principleHeading)
                            .join(", ")
                        : "select kpi"
                    }
                    // onDoubleClick={()=>handleShow(topicMapping && topicMapping.length
                    //   ? topicMapping
                    //       .map((item) => item.principleHeading)
                    //       .join(", ")
                    //   : "select kpi")}
                    readOnly
                  />
                </div>
                <div className="col-md-6 ms-3">
                  <label>Details</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    placeholder="Details of the training"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <label>Training Facilitator</label>
                  <input
                    type="text"
                    className="form-control"
                    name="trainingFacilitator"
                    placeholder="Name"
                    value={formData.trainingFacilitator}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 ms-3">
                  <label>Trainers</label>
                  <input
                    type="text"
                    className="form-control"
                    name="trainers"
                    placeholder="Add up-to 3 trainers"
                    value={formData.trainers}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-3">
                  <label>Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="date"
                    value={formatDateToInputValue(formData.date)}
                    onChange={handleChange}
                    min={new Date().toISOString().split("T")[0]} // Set minimum date to today
                  />
                </div>

                <div className="col-md-3">
                  <label>From </label>
                  <input
                    type="time"
                    className="form-control"
                    name="fromTime"
                    value={formData.fromTime}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <label>To </label>
                  <input
                    type="time"
                    className="form-control"
                    name="toTime"
                    value={formData.toTime}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className="col-md-3">
                  <label>Registration DeadLine</label>
                  <input
                    type="date"
                    className="form-control"
                    name="registrationDeadline"
                    value={formatDateToInputValue(
                      formData.registrationDeadline
                    )}
                    onChange={handleChange}
                    min={new Date().toISOString().split('T')[0]} 
                  />
                </div> */}

                <div className="col-md-4">
                  <label>Target Audience</label>
                  <div>
                    <div
                      className="form-control control-form"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      {selectedOptions.length > 0
                        ? "Select Audience"
                        : "Select Audience"}
                    </div>

                    {isDropdownOpen && (
                      <div
                        className="dropdown-menu show"
                        style={{
                          display: "block",
                          position: "absolute",
                          zIndex: 1,
                          width: "30%",
                          height: "20vh",
                          overflow: "auto",
                        }}
                      >
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            value="ALL"
                            checked={isAllSelected}
                            onChange={() => toggleOption("ALL")}
                            className="custom-checkbox"
                          />
                          <span>Select All</span>
                        </label>
                        {audienceOptions.map((option) => (
                          <label key={option.value} className="checkbox-label">
                            <input
                              type="checkbox"
                              value={option.value}
                              checked={selectedOptions.includes(option.value)}
                              onChange={() => toggleOption(option.value)}
                              className="custom-checkbox"
                            />
                            <span>{option.label}</span>
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <label>Mode Of Training</label>
                  <select
                    className="form-control"
                    name="modeOfTraining"
                    value={formData.modeOfTraining}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    <option value="ONLINE">Online</option>
                    <option value="OFFLINE">Offline</option>
                  </select>
                </div>
                <div className="col-md-6 ms-3">
                  <label>Add Location</label>
                  <input
                    type="text"
                    className="form-control"
                    name="linkOrVenues"
                    placeholder="Add venue link or location"
                    value={formData.linkOrVenues}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-end">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        style={{ height: "max-content " }}
        show={showContent}
        onHide={handleContentClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleContentClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleContentClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TrainingModal;
